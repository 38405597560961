import { TableCell, TableRow } from '@fluentui/react-components';
import { Globe20Regular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useAsyncEffect from 'use-async-effect';
import { Indicator } from '../common';
import { delay } from '@microsoft/vscodeedu-api';

type FetchState = 'Loading' | 'Succeeded' | 'Failed';

// Domain table row properties.
export type DomainTableRowProps = Readonly<{
    displayName: string;
    pingUrl: string;
    description?: string;
}>;

// Domain table row.
export default (props: DomainTableRowProps) => {
    const { displayName, pingUrl, description } = props;
    const [state, setState] = useState<FetchState>('Loading');

    // Fetch ping URL using HTTP HEAD, and retry on failure.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useAsyncEffect(async () => {
        for (let i = 0; i < 2; i++) {
            try {
                await fetch(pingUrl, { method: 'HEAD', mode: 'no-cors' });
                setState('Succeeded');
                return;
            } catch (e) {
                if (i === 0) {
                    await delay(500);
                }
            }
        }
        setState('Failed');
    }, [pingUrl, setState]);

    return (
        <TableRow>
            <TableCell>
                <Indicator icon={<Globe20Regular />} text={displayName} />
            </TableCell>
            <TableCell>
                {state === 'Loading' && (
                    <Indicator type="spinner">
                        <FormattedMessage description="Domains table loading label" defaultMessage="Checking" />
                    </Indicator>
                )}
                {state === 'Succeeded' && (
                    <Indicator type="success">
                        <FormattedMessage description="Domains table success label" defaultMessage="Accessible" />
                    </Indicator>
                )}
                {state === 'Failed' && (
                    <Indicator type="error">
                        <FormattedMessage description="Domains table failure label" defaultMessage="Not accessible" />
                    </Indicator>
                )}
            </TableCell>
            <TableCell>{description}</TableCell>
        </TableRow>
    );
};
