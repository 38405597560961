import { compareLocale } from '@microsoft/vscodeedu-api';
import { useMemo } from 'react';
import { IntlShape } from 'react-intl';
import { DomainInfo } from '../models/domain';
import { useConfig } from './config-context';

// Returns list of domains used by VS Code for Education services.
export const useDomains = (intl: IntlShape) => {
    const { cleverAuth, curriculumAuthoring, ocvFeedback } = useConfig();
    return useMemo(() => {
        // All domains below must be listed in the connect-src csp override in config-overrides.js
        const value: DomainInfo[] = [
            {
                category: 'required',
                displayName: 'auth.vscode.dev',
                pingUrl: 'https://auth.vscode.dev',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by VS Code for Education IDE to authenticate users. Required for all scenarios.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: 'default.exp-tas.com',
                pingUrl: 'https://default.exp-tas.com',
                description: intl.formatMessage({
                    defaultMessage: 'Used by VS Code for Education IDE. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: 'marketplace.visualstudio.com',
                pingUrl: 'https://marketplace.visualstudio.com',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by VS Code for Education IDE to download extensions. Required for all scenarios.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: '*.vscode-cdn.net',
                pingUrl: 'https://www.vscode-cdn.net',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by VS Code for Education IDE to download extensions. Required for all scenarios.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: '*.vscode-unpkg.net',
                pingUrl: 'https://www.vscode-unpkg.net',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by VS Code for Education IDE to download extensions. Required for all scenarios.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: 'vscode.dev',
                pingUrl: 'https://vscode.dev',
                description: intl.formatMessage({
                    defaultMessage: 'VS Code for Education IDE. Required for all scenarios.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: 'vscodeedu.com',
                pingUrl: 'https://vscodeedu.com',
                description: intl.formatMessage({
                    defaultMessage: 'VS Code for Education home page. Required for all scenarios.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'required',
                displayName: '*.vscodeedu.app',
                pingUrl: 'https://vscodeedu.app',
                description: intl.formatMessage({
                    defaultMessage:
                        'Hosts web sites created by users. Enable if users will publish their own web sites.',
                    description: 'Description for a domain name.',
                }),
            },
        ];

        // Microsoft auth domains
        value.push(
            {
                category: 'auth',
                displayName: 'aadcdn.msftauth.net',
                pingUrl: 'https://aadcdn.msftauth.net',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by Microsoft authentication service. Enable if users will use Microsoft accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'auth',
                displayName: 'aadcdn.msftauthimages.net',
                pingUrl: 'https://aadcdn.msftauthimages.net',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by Microsoft authentication service. Enable if users will use Microsoft accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'auth',
                displayName: 'login.live.com',
                pingUrl: 'https://login.live.com',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by Microsoft authentication service. Enable if users will use Microsoft accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'auth',
                displayName: 'login.microsoftonline.com',
                pingUrl: 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration',
                description: intl.formatMessage({
                    defaultMessage:
                        'Microsoft authentication service. Enable if users will use Microsoft accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            }
        );

        // Google auth domains
        value.push(
            {
                category: 'google-auth',
                displayName: 'accounts.google.com',
                pingUrl: 'https://accounts.google.com',
                description: intl.formatMessage({
                    defaultMessage:
                        'Google authentication service. Enable if users will use Google accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'google-auth',
                displayName: 'myaccount.google.com',
                pingUrl: 'https://myaccount.google.com',
                description: intl.formatMessage({
                    defaultMessage: 'Google account home page. Enable if users will use Google accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'google-auth',
                displayName: 'oauth2.googleapis.com',
                pingUrl: 'https://oauth2.googleapis.com',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by Google authentication service. Enable if users will use Google accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'google-auth',
                displayName: 'ogs.google.com',
                pingUrl: 'https://ogs.google.com',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by Google authentication service. Enable if users will use Google accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'google-auth',
                displayName: 'securetoken.googleapis.com',
                pingUrl: 'https://securetoken.googleapis.com',
                description: intl.formatMessage({
                    defaultMessage:
                        'Used by Google authentication service. Enable if users will use Google accounts to sign in.',
                    description: 'Description for a domain name.',
                }),
            }
        );

        if (cleverAuth) {
            // Clever auth domains
            // https://support.clever.com/hc/s/articles/203256516?language=en_US
            value.push(
                {
                    category: 'clever-auth',
                    displayName: '*.clever.com',
                    pingUrl: 'https://www.clever.com/',
                    description: intl.formatMessage({
                        defaultMessage: 'Clever authentication service. Enable if users will use Clever to sign in.',
                        description: 'Description for a domain name.',
                    }),
                },
                {
                    category: 'clever-auth',
                    displayName: '*.s3.amazonaws.com',
                    pingUrl: 'https://s3.amazonaws.com',
                    description: intl.formatMessage({
                        defaultMessage:
                            'Used by Clever authentication service. Enable if users will use Clever to sign in.',
                        description: 'Description for a domain name.',
                    }),
                },
                {
                    category: 'clever-auth',
                    displayName: '*.twilio.com',
                    pingUrl: 'https://www.twilio.com/en-us',
                    description: intl.formatMessage({
                        defaultMessage:
                            'Used by Clever authentication service. Enable if users will use Clever to sign in.',
                        description: 'Description for a domain name.',
                    }),
                }
            );
        }

        if (curriculumAuthoring) {
            // Curriculum authoring domains
            value.push(
                {
                    category: 'authoring',
                    displayName: 'github.com',
                    pingUrl: 'https://github.com/',
                    description: intl.formatMessage({
                        defaultMessage:
                            'Used to host custom curricula content. Enable if users will create their own lessons.',
                        description: 'Description for a domain name.',
                    }),
                },
                {
                    category: 'authoring',
                    displayName: 'api.github.com',
                    pingUrl: 'https://api.github.com/',
                    description: intl.formatMessage({
                        defaultMessage:
                            'Used to host custom curricula content. Enable if users will create their own lessons.',
                        description: 'Description for a domain name.',
                    }),
                }
            );
        }

        // Community domains
        value.push(
            {
                category: 'community',
                displayName: 'community.vscodeedu.com',
                pingUrl: 'https://community.vscodeedu.com',
                description: intl.formatMessage({
                    defaultMessage: 'VS Code for Education community forum. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'community',
                displayName: '*.discourse-cdn.com',
                pingUrl: 'https://canada1.discourse-cdn.com',
                description: intl.formatMessage({
                    defaultMessage: 'Used by VS Code for Education community forum. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            }
        );

        if (ocvFeedback) {
            // Product feedback domains
            value.push(
                {
                    category: 'feedback',
                    displayName: 'admin.microsoft.com',
                    pingUrl: 'https://admin.microsoft.com',
                    description: intl.formatMessage({
                        defaultMessage: 'Used to send product feedback to Microsoft. Can be omitted.',
                        description: 'Description for a domain name.',
                    }),
                },
                {
                    category: 'feedback',
                    displayName: 'res.cdn.office.net',
                    pingUrl: 'https:///res.cdn.office.net',
                    description: intl.formatMessage({
                        defaultMessage: 'Used to send product feedback to Microsoft. Can be omitted.',
                        description: 'Description for a domain name.',
                    }),
                },
                {
                    category: 'feedback',
                    displayName: 'petrol.office.microsoft.com',
                    pingUrl: 'https://petrol.office.microsoft.com',
                    description: intl.formatMessage({
                        defaultMessage: 'Used to send product feedback to Microsoft. Can be omitted.',
                        description: 'Description for a domain name.',
                    }),
                }
            );
        }

        // Telemetry domains
        value.push(
            {
                category: 'telemetry',
                displayName: 'browser.events.data.msn.com',
                pingUrl: 'https://browser.events.data.msn.com',
                description: intl.formatMessage({
                    defaultMessage: 'Used to send product improvement telemetry to Microsoft. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'telemetry',
                displayName: 'mobile.events.data.microsoft.com',
                pingUrl: 'https://mobile.events.data.microsoft.com',
                description: intl.formatMessage({
                    defaultMessage: 'Used to send product improvement telemetry to Microsoft. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'telemetry',
                displayName: 'westus3-1.in.applicationinsights.azure.com',
                pingUrl: 'https://westus3-1.in.applicationinsights.azure.com',
                description: intl.formatMessage({
                    defaultMessage: 'Used to send product improvement telemetry to Microsoft. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'telemetry',
                displayName: 'js.monitor.azure.com',
                pingUrl: 'https://js.monitor.azure.com',
                description: intl.formatMessage({
                    defaultMessage: 'Used to send product improvement telemetry to Microsoft. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            }
        );

        // Settings sync domains
        value.push(
            {
                category: 'settings',
                displayName: '*.gallery.vsassets.io',
                pingUrl: 'https://www.gallery.vsassets.io',
                description: intl.formatMessage({
                    defaultMessage: 'VS Code user settings sync. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'settings',
                displayName: '*.gallerycdn.vsassets.io',
                pingUrl: 'https://www.gallerycdn.vsassets.io',
                description: intl.formatMessage({
                    defaultMessage: 'VS Code user settings sync. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            },
            {
                category: 'settings',
                displayName: 'vscode-sync.trafficmanager.net',
                pingUrl: 'https://vscode-sync.trafficmanager.net',
                description: intl.formatMessage({
                    defaultMessage: 'VS Code user settings sync. Can be omitted.',
                    description: 'Description for a domain name.',
                }),
            }
        );

        value.sort((a, b) => compareLocale(a.displayName, b.displayName));
        return value;
    }, [intl, cleverAuth, curriculumAuthoring, ocvFeedback]);
};
