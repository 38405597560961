import { Accordion, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../components/common';
import DomainsTable from '../components/domains-table/domains-table';
import PageContainer from '../components/page-container';
import UnsafeMarkdownContent from '../components/unsafe-markdown-content';
import { useDomains } from '../data';
import { useAppStyles, useMarkdownStyles } from '../styles';

// Domains list page.
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useAppStyles();
    const domains = useDomains(intl);
    useMarkdownStyles();

    return (
        <PageContainer className={styles.pageContainer}>
            <div className={mergeClasses(styles.pageContent, 'content-width')}>
                <div className="markdown-content">
                    <UnsafeMarkdownContent src="/docs/required-domains.md" />
                </div>
                <Accordion collapsible multiple defaultOpenItems={['required']}>
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Visual Studio Code for Education',
                            description: 'Label for a group of domain names',
                        })}
                        value="required"
                        items={domains.filter((o) => o.category === 'required')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Microsoft Authentication (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="auth"
                        items={domains.filter((o) => o.category === 'auth')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Google Authentication (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="google-auth"
                        items={domains.filter((o) => o.category === 'google-auth')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Clever Authentication (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="clever-auth"
                        items={domains.filter((o) => o.category === 'clever-auth')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Curriculum Authoring (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="authoring"
                        items={domains.filter((o) => o.category === 'authoring')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Community Forum (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="community"
                        items={domains.filter((o) => o.category === 'community')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Product Feedback (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="feedback"
                        items={domains.filter((o) => o.category === 'feedback')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Product Telemetry (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="telemetry"
                        items={domains.filter((o) => o.category === 'telemetry')}
                    />
                    <DomainsTable
                        label={intl.formatMessage({
                            defaultMessage: 'Settings Sync (Optional)',
                            description: 'Label for a group of domain names',
                        })}
                        value="sync"
                        items={domains.filter((o) => o.category === 'settings')}
                    />
                </Accordion>
            </div>
        </PageContainer>
    );
});
