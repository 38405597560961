import { mergeClasses } from '@fluentui/react-components';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LoadingCard, TrackContext } from '../components/common';
import CourseCard from '../components/course-card/course-card';
import PageContainer from '../components/page-container';
import { CourseContext, getActivityCourses, getCurriculumCourses, getIsLoadingCourses } from '../data';
import { useAppStyles } from '../styles';

// Course catalog.
export default () => {
    const appStyles = useAppStyles();
    const [store] = useContext(CourseContext);
    const isLoading = getIsLoadingCourses(store);
    const curricula = getCurriculumCourses(store);
    const activities = getActivityCourses(store);
    const navigate = useNavigate();

    return (
        <TrackContext.Provider value={{ container: 'course-catalog' }}>
            <PageContainer className={appStyles.pageContainer}>
                <div className={mergeClasses(appStyles.pageContent, 'content-width')}>
                    <div className={appStyles.section}>
                        <h1 className={appStyles.sectionTitle}>
                            <FormattedMessage description="Courses page section title" defaultMessage="Courses" />
                        </h1>
                        <div className={mergeClasses(appStyles.sectionContent, appStyles.cardsContainer)}>
                            {isLoading && <LoadingCard showImage />}
                            {!isLoading &&
                                curricula.map((o) => (
                                    <CourseCard
                                        {...o}
                                        tags={undefined}
                                        key={o.id}
                                        className={appStyles.cardThreeColumns}
                                        onClick={() => navigate(`${o.id}`)}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className={appStyles.section}>
                        <h1 className={appStyles.sectionTitle}>
                            <FormattedMessage
                                description="Courses page section title"
                                defaultMessage="Coding Activities"
                            />
                        </h1>
                        <div className={mergeClasses(appStyles.sectionContent, appStyles.cardsContainer)}>
                            {isLoading && <LoadingCard showImage />}
                            {!isLoading &&
                                activities.map((o) => (
                                    <CourseCard
                                        {...o}
                                        tags={undefined}
                                        key={o.id}
                                        className={appStyles.cardThreeColumns}
                                        onClick={() => navigate(`${o.id}`)}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </PageContainer>
        </TrackContext.Provider>
    );
};
