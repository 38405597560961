import {
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Table,
    TableBody,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    tokens,
} from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DomainInfo } from '../../models';
import DomainTableRow from './domains-table-row';

const useStyles = makeStyles({
    root: {
        marginLeft: tokens.spacingHorizontalXXXL,
    },
});

// Domains table properties.
export type DomainTableProps = Readonly<{
    value: string;
    label: JSX.Element | string;
    items: ReadonlyArray<DomainInfo>;
}>;

// Domains table.
export default (props: DomainTableProps) => {
    const { value, label, items } = props;
    const styles = useStyles();

    if (items.length === 0) {
        return <></>;
    }

    return (
        <AccordionItem value={value}>
            <AccordionHeader>{label}</AccordionHeader>
            <AccordionPanel>
                <Table className={styles.root} size="small">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell style={{ width: '15rem' }}>
                                <FormattedMessage
                                    description="Domains page domain column header"
                                    defaultMessage="Domain"
                                />
                            </TableHeaderCell>
                            <TableHeaderCell style={{ width: '10rem' }}>
                                <FormattedMessage
                                    description="Domains page status column header"
                                    defaultMessage="Status"
                                />
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <FormattedMessage
                                    description="Domains page description column header"
                                    defaultMessage="Description"
                                />
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {items.map((props) => (
                            <DomainTableRow key={props.pingUrl} {...props} />
                        ))}
                    </TableBody>
                </Table>
            </AccordionPanel>
        </AccordionItem>
    );
};
