import { Image, ImageProps } from '@fluentui/react-components';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../components/common';
import { getFetchUrl } from '../utilities';
import { useTheme } from '../data/theme-context';

// Account image.
export const AccountImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/account.png')}
        alt={props.intl.formatMessage({
            description: 'Account image alt text.',
            defaultMessage: 'Image showing person in circle with colored points on it.',
        })}
    />
));

// Create new item image.
export const CreateImage = injectIntl((props: IntlProps<ImageProps>) => {
    const [themeState, _] = useTheme();
    return (
        <Image
            {...props}
            src={getFetchUrl(themeState.isDarkTheme ? '/create.png' : '/create-light.png')}
            alt={props.intl.formatMessage({
                description: 'Create image alt text.',
                defaultMessage: 'Image showing file with a plus sign over it.',
            })}
        />
    );
});

// Cloud storage image.
export const CloudStorageImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/one-drive.png')}
        alt={props.intl.formatMessage({
            description: 'Cloud storage image alt text.',
            defaultMessage: 'Image showing cloud with a file on top of it, and arrow pointing at the file.',
        })}
    />
));

// Warning (exclamation mark in triangle) image.
export const WarningImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/warning.png')}
        alt={props.intl.formatMessage({
            description: 'Warning image alt text.',
            defaultMessage: 'Image showing exclamation mark in a triangle.',
        })}
    />
));

// Sign-in image.
export const SignInImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/sign-in.png')}
        alt={props.intl.formatMessage({
            description: 'Sign-in image alt text.',
            defaultMessage: 'Image showing person upper body with a check mark.',
        })}
    />
));

// School (backpack) image.
export const SchoolImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/school.png')}
        alt={props.intl.formatMessage({
            description: 'School image alt text.',
            defaultMessage: 'Image showing school backpack with zipper pocket and a yellow star on top of it.',
        })}
    />
));

// Search image.
export const SearchImage = injectIntl((props: IntlProps<ImageProps>) => (
    <Image
        {...props}
        src={getFetchUrl('/search.png')}
        alt={props.intl.formatMessage({
            description: 'Search image alt text.',
            defaultMessage: 'Image showing question mark over looking glass.',
        })}
    />
));