import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { ArrowLeft28Regular, ArrowRight28Regular } from '@fluentui/react-icons';
import React, { useId, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingCard, TrackContext, TrackedButton } from '../components/common';
import FeedbackGroup from '../components/common/feedback-group';
import PageNavigator from '../components/common/page-navigator';
import PageContainer from '../components/page-container';
import PinnedPostCard from '../components/post-card/pinned-post-card';
import PostCard from '../components/post-card/post-card';
import { getPinnedPost, getPosts, usePosts } from '../data/post-context';
import { useAppStyles } from '../styles';

const useStyles = makeStyles({
    buttonPlaceholder: {
        width: '50%',
    },
    button: {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.padding(tokens.spacingHorizontalL),
        ...shorthands.outline('1px', 'solid', tokens.colorNeutralStroke1),
    },
    buttonContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.5rem',
        width: '100%',
    },
    alignRight: {
        textAlign: 'right',
    },
    alignLeft: {
        textAlign: 'left',
    },
    content: {
        minHeight: 'calc(100vh - 23rem)',
    },
    navigationButtons: {
        paddingBottom: tokens.spacingVerticalS,
    },
});

// What's New page.
export default () => {
    const styles = useStyles();
    const appStyles = useAppStyles();
    const navigate = useNavigate();
    const [store] = usePosts();
    const posts = getPosts(store);
    const pinnedPost = getPinnedPost(store);

    const containerId = useId();

    // Keep track of previous, current, and next posts.
    const params = useParams();
    const [prev, current, next] = useMemo(() => {
        const index = posts.findIndex((o) => o.id === params['postId']);
        return [
            index > 0 ? posts[index - 1] : undefined,
            posts[index],
            index < posts.length - 1 ? posts[index + 1] : undefined,
        ];
    }, [posts, params]);

    return (
        <TrackContext.Provider value={{ container: 'whats-new' }}>
            <PageContainer className={appStyles.pageContainer}>
                <div className={mergeClasses(appStyles.pageContent, 'content-width')}>
                    {!current && (
                        <>
                            {pinnedPost && <PinnedPostCard post={pinnedPost} />}
                            <div className={appStyles.section}>
                                <h1 className={appStyles.sectionTitle}>
                                    <FormattedMessage
                                        description="What's New page section title"
                                        defaultMessage="What's New"
                                    />
                                </h1>
                                <div className={appStyles.sectionContent}>
                                    <FormattedMessage
                                        description="Text on the What's New page"
                                        defaultMessage="Bookmark this page to find product updates, release notes, curriculum announcements, and community updates."
                                    />
                                </div>
                                <div className={mergeClasses(appStyles.sectionContent, appStyles.cardsContainer)}>
                                    {!posts.length && <LoadingCard showImage />}
                                    {posts.length > 0 &&
                                        posts.map((o) => (
                                            <PostCard
                                                {...o}
                                                key={o.id}
                                                className={appStyles.cardThreeColumns}
                                                onClick={() => navigate(`${o.id}`)}
                                            />
                                        ))}
                                </div>
                            </div>
                        </>
                    )}
                    {current && (
                        <>
                            <div className={mergeClasses(appStyles.section, styles.content)}>
                                <div className={appStyles.flexRowSmallGap}>
                                    <div className={appStyles.flexColumn}>
                                        <h1 className={appStyles.sectionTitle}>{current.title}</h1>
                                        <div id={containerId} className={appStyles.sectionContent}>
                                            {current.content}
                                        </div>
                                    </div>
                                    <PageNavigator containerId={containerId} />
                                </div>
                            </div>
                            <div className={appStyles.section}>
                                <FeedbackGroup qid2="What's New" qid3={current.title} qid4={current.rssId} />
                            </div>
                            <div className={mergeClasses(appStyles.flexRowSmallGap, styles.navigationButtons)}>
                                {prev && (
                                    <TrackedButton
                                        trackAction="prev-post"
                                        className={styles.button}
                                        appearance="subtle"
                                        onClick={() => navigate(`/whats-new/${prev.id}`)}
                                    >
                                        <ArrowLeft28Regular />
                                        <div className={mergeClasses(styles.buttonContent, styles.alignRight)}>
                                            <div className={mergeClasses(appStyles.textSmall, appStyles.textLight)}>
                                                <FormattedMessage
                                                    description="Previous post"
                                                    defaultMessage="Previous"
                                                />
                                            </div>
                                            {prev.title}
                                        </div>
                                    </TrackedButton>
                                )}
                                {!prev && <div className={styles.buttonPlaceholder}></div>}
                                {next && (
                                    <TrackedButton
                                        trackAction="next-post"
                                        className={styles.button}
                                        appearance="subtle"
                                        onClick={() => navigate(`/whats-new/${next.id}`)}
                                    >
                                        <div className={mergeClasses(styles.buttonContent, styles.alignLeft)}>
                                            <div className={mergeClasses(appStyles.textSmall, appStyles.textLight)}>
                                                <FormattedMessage description="Next post" defaultMessage="Next" />
                                            </div>
                                            {next.title}
                                        </div>
                                        <ArrowRight28Regular />
                                    </TrackedButton>
                                )}
                                {!next && <div className={styles.buttonPlaceholder}></div>}
                            </div>
                        </>
                    )}
                </div>
            </PageContainer>
        </TrackContext.Provider>
    );
};
