import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    makeStyles,
    tokens,
} from '@fluentui/react-components';
import { LineHorizontal3Regular } from '@fluentui/react-icons';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserContext, getIsSignedIn, useConfig } from '../../data';
import { useAppStyles } from '../../styles';
import { IntlProps } from '../common';

const useStyles = makeStyles({
    navLink: {
        marginLeft: '2rem',
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.lineHeightBase400,
        textDecorationLine: 'none !important',
        color: tokens.colorNeutralForeground1,
        '&.active': {
            WebkitTextStrokeWidth: '0.05ex',
        },
        '&:focus-visible': {
            outline: `2px solid ${tokens.colorStrokeFocus2}`,
        },
    },
});

// Header's far end group of controls (mostly related to identity).
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useStyles();
    const appStyles = useAppStyles();
    const navigate = useNavigate();
    const [store] = useContext(UserContext);
    const isSignedIn = getIsSignedIn(store);
    const { communityUrl, curriculumAuthoring } = useConfig();

    const links = useMemo(() => {
        const result = [
            {
                label: intl.formatMessage({
                    description: 'Header navigation link text',
                    defaultMessage: 'Catalog',
                }),
                link: '/courses',
            },
            {
                label: intl.formatMessage({
                    description: 'Header navigation link text',
                    defaultMessage: 'Educators',
                }),
                link: '/educators',
            },
            {
                label: intl.formatMessage({
                    description: 'Header navigation link text',
                    defaultMessage: 'Community',
                }),
                link: communityUrl,
            },
            {
                label: intl.formatMessage({
                    description: 'Header navigation link text',
                    defaultMessage: "What's New",
                }),
                link: '/whats-new',
            },
        ];

        if (isSignedIn) {
            result.unshift({
                label: intl.formatMessage({
                    description: 'Header navigation link text',
                    defaultMessage: 'My Work',
                }),
                link: '/my-work',
            });
            if (curriculumAuthoring) {
                result.splice(2, 0, {
                    label: intl.formatMessage({
                        description: 'Header navigation link text',
                        defaultMessage: 'Authoring',
                    }),
                    link: '/authoring',
                });
            }
        }

        return result;
    }, [communityUrl, curriculumAuthoring, intl, isSignedIn]);

    return (
        <>
            <div className={appStyles.desktopOnly}>
                {links.map(({ label, link }, i) => (
                    <NavLink className={styles.navLink} key={i} to={link}>
                        {label}
                    </NavLink>
                ))}
            </div>
            <div className={appStyles.desktopHidden}>
                <Menu>
                    <MenuTrigger>
                        <Button
                            appearance="subtle"
                            icon={<LineHorizontal3Regular />}
                            title={intl.formatMessage({
                                description: 'Alt text for header menu button',
                                defaultMessage: 'Navigation menu',
                            })}
                        />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={() => navigate('/')}>
                                <FormattedMessage description="Header navigation link text" defaultMessage="Home" />
                            </MenuItem>
                            {links.map(({ label, link }, i) => (
                                <MenuItem key={i} onClick={() => navigate(link)}>
                                    {label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        </>
    );
});
