import { ButtonProps } from '@fluentui/react-components';
import { CopyRegular } from '@fluentui/react-icons';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useAsyncRunner } from '../../utilities/async-runner';
import { IntlProps } from './intl-props';
import { TrackedButton } from './tracked-controls';

// Copy button properties.
export type CopyToClipboardButtonProps = IntlProps<
    ButtonProps & {
        text: string;
        link?: boolean;
    }
>;

// Copy text to clipboard button.
export default injectIntl((props: CopyToClipboardButtonProps) => {
    const { text, link, intl } = props;
    const isLink = link === true;
    const runner = useAsyncRunner();

    const onClick = useCallback(
        async () =>
            runner.run({
                task: async () => navigator.clipboard.writeText(text),
                successMessage: isLink
                    ? intl.formatMessage({
                          description: 'Message after link has been copied to clipboard.',
                          defaultMessage: 'Successfully copied link to the clipboard.',
                      })
                    : intl.formatMessage({
                          description: 'Message after text has been copied to clipboard.',
                          defaultMessage: 'Successfully copied text to the clipboard.',
                      }),
                errorMessage: intl.formatMessage({
                    description: 'Message about failure to copy text to the clipboard.',
                    defaultMessage: 'An error occurred while copying text to the clipboard.',
                }),
                errorDetails: intl.formatMessage({
                    description: 'Subtitle on a toast with message about failure to copy text.',
                    defaultMessage: 'Please ensure you allowed this page access to the clipboard.',
                }),
            }),
        [runner, isLink, intl, text]
    );

    return (
        <TrackedButton
            trackAction={isLink ? 'copy-link' : 'copy-text'}
            onClick={onClick}
            appearance="subtle"
            icon={<CopyRegular />}
            title={
                isLink
                    ? intl.formatMessage({
                          description: 'Tooltip for the Copy button.',
                          defaultMessage: 'Copy link to the clipboard.',
                      })
                    : intl.formatMessage({
                          description: 'Tooltip for the Copy button.',
                          defaultMessage: 'Copy text to the clipboard.',
                      })
            }
            {...props}
        />
    );
});
